.header .logo{
    margin:auto !important;
    padding: 30px 0px 0px 0px;
}
.rec-dot{
    display: none !important;
}
.flat-information,.one-half,.popular-new{
    list-style: none;
}
.tp-mask-wrap{
    overflow: inherit !important;
}
.unstyled{
    list-style: none;
}

/* slider */
.previousButton, .nextButton{
    display: none;
}
